<template>
  <section id="app-ads" class="pt-4 pb-5 text-black px-4">
    <SectionTitleHeader :title="$t('message.check-out-the') + ' ' + $t('message.socratix-app')" color="science-dark" textColor="black" class="pt-4 mb-5" />
    <img alt="Socratix App Image" :src="socratixMockup" width="295" height="170" class="mb-1" data-aos="zoom-in" />
    <article>
      <h3 class="section-subtitle --big font-weight-bold mt-2 text-start" data-aos="fade-right">
        {{ $t('message.learn-something-new-everyday') }}
      </h3>
      <p class="legend --big font-weight-regular mb-0 text-start" data-aos="fade-right" data-aos-delay="250">
        {{ $t('message.socratix-description') }}
      </p>
    </article>
    <div class="d-flex mt-5 mb-4 justify-content-evenly">
      <a
        @click="$emit('event', 'click', { button: 'app_ad', device: 'android' })"
        href="https://play.google.com/store/apps/details?id=ai.socratic.app&hl=en&gl=US"
        rel="noopener"
        target="_blank"
        title="get it on Google Play"
      >
        <img :src="googlePlayIcon" width="141" height="42" data-aos-offset="-100" data-aos="zoom-in" data-aos-delay="500" alt="get it on Google Play" />
      </a>
      <a
        @click="$emit('event', 'click', { button: 'app_ad', device: 'ios' })"
        href="https://apps.apple.com/us/app/question-of-the-day-socratix/id1522896770"
        rel="noopener"
        target="_blank"
        title="download on the App Store"
      >
        <img :src="appStoreIcon" width="141" height="42" data-aos-offset="-100" data-aos="zoom-in" data-aos-delay="750" alt="download on the App Store" />
      </a>
    </div>
  </section>
</template>
<script>
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  components: {
    SectionTitleHeader
  },
  computed: {
    socratixMockup() {
      return require('@/assets/images/iphone-x-flying-socratix-mockup.png');
    },
    appStoreIcon() {
      return require('@/assets/images/app-store-badge.svg');
    },
    googlePlayIcon() {
      return require('@/assets/images/google-play-badge.png');
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/assets/custom-vars/_colors.scss';

#app-ads {
  background: $gradient-history;
}
</style>
