<template>
  <div class="ballon bg-white d-flex align-items-center" :class="['bg-' + (answer ? 'general-knowledge ' : 'white')]" :data-aos="answer ? 'fade-left' : 'fade-right'">
    <svg width="42" height="18" viewBox="0 0 21 9" class="mt-auto">
      <path d="M 14.49996,0 C 12.33336,3 4.99987,8 0,9 h 21 z" fill="#ffffff" />
    </svg>
    <p class="text-black legend mb-0 px-2 flex-fill">
      <b>
        <i>{{ text }}</i>
      </b>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String, required: true },
    answer: { type: Boolean, default: () => false }
  }
};
</script>
<style lang="scss">
@import '@/assets/custom-vars/_colors.scss';

.ballon {
  width: 170px;
  height: 65px;
  border-radius: 32.5px;
  &.max-width {
    width: auto;
    max-width: 295px;
  }
  svg {
    z-index: 1;
    position: absolute;
    transform: translate(-8px, 16px) scaleX(-1);
  }
  p {
    z-index: 2;
  }
  &.to-right {
    > svg {
      left: calc(100% + 8px);
      transform: translate(-100%, 16px);
      > path {
        fill: $general-knowledge;
      }
    }
  }
}
</style>
